<template>
	<div></div>
</template>

<script>
	
	export default{
		name: 'Main'
		,props: ['Axios']
		,data: function(){
			return {

			}
		}
		
		,created: function(){
			this.$bus.$emit('to', { name: 'Index'})
			//this.$bus.$emit('notify', { type: 'success', message: 'base template'})
		}
	}
	
</script>